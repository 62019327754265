var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):_c('div',{staticClass:"general-setting"},[_c('validation-observer',{ref:"generalSettingform",staticClass:"w-100"},[_c('b-form',[_c('b-row',{staticClass:"mx-1"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("g.settings/generalSetting"))+" ")])]),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/sitename'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('settings/placeholder/enter_the_name_here')},model:{value:(_vm.form_data.site_name),callback:function ($$v) {_vm.$set(_vm.form_data, "site_name", $$v)},expression:"form_data.site_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/VirtualCurrency'),"label-for":"VirtualCurrency"}},[_c('validation-provider',{attrs:{"name":"VirtualCurrency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.currencyData,"value-field":"currency","disabled":!_vm.canEdit,"text-field":"currency","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.currency),callback:function ($$v) {_vm.$set(_vm.form_data, "currency", $$v)},expression:"form_data.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/language'),"label-for":"language"}},[_c('validation-provider',{attrs:{"name":"language"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.canEdit,"options":_vm.locale},model:{value:(_vm.form_data.locale),callback:function ($$v) {_vm.$set(_vm.form_data, "locale", $$v)},expression:"form_data.locale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/shippingCost'),"label-for":"shipping cost"}},[_c('validation-provider',{attrs:{"name":"shipping cost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit},model:{value:(_vm.form_data.shipping_cost),callback:function ($$v) {_vm.$set(_vm.form_data, "shipping_cost", $$v)},expression:"form_data.shipping_cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/free_shipping_order_value'),"label-for":"Min stock"}},[_c('validation-provider',{attrs:{"name":"Min stock"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit},model:{value:(_vm.form_data.free_shipping_order_value),callback:function ($$v) {_vm.$set(_vm.form_data, "free_shipping_order_value", $$v)},expression:"form_data.free_shipping_order_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/VAT'),"label-for":"VAT"}},[_c('validation-provider',{attrs:{"name":"VAT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit},model:{value:(_vm.form_data.vat_amount),callback:function ($$v) {_vm.$set(_vm.form_data, "vat_amount", $$v)},expression:"form_data.vat_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/min_stock'),"label-for":"Min stock"}},[_c('validation-provider',{attrs:{"name":"Min stock"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.min_stock),callback:function ($$v) {_vm.$set(_vm.form_data, "min_stock", $$v)},expression:"form_data.min_stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/site_description'),"label-for":"site_description"}},[_c('validation-provider',{attrs:{"name":"meta_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.form_data.site_description),callback:function ($$v) {_vm.$set(_vm.form_data, "site_description", $$v)},expression:"form_data.site_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/meta_description'),"label-for":"free_shipping_order"}},[_c('validation-provider',{attrs:{"name":"meta_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":!_vm.canEdit},model:{value:(_vm.form_data.meta_description),callback:function ($$v) {_vm.$set(_vm.form_data, "meta_description", $$v)},expression:"form_data.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/meta_keyword'),"label-for":"meta_keyword"}},[_c('validation-provider',{attrs:{"name":"meta_keyword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":!_vm.canEdit},model:{value:(_vm.form_data.meta_keyword),callback:function ($$v) {_vm.$set(_vm.form_data, "meta_keyword", $$v)},expression:"form_data.meta_keyword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/logoImg'),"label-for":"logoImg"}},[_c('div',{staticClass:"logoimgPreview",on:{"click":function($event){return _vm.$refs.logoFileImg.$el.click()}}},[_c('label',{attrs:{"for":"logoFileImg"}},[(_vm.logo)?_c('img',{staticClass:"img",attrs:{"src":_vm.logo}}):_c('div',{staticClass:"addMediaBlock"},[_c('img',{attrs:{"src":_vm.addImageIcon}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("g.settings/generalSettings/addlogoImg"))+" ")])])])]),_c('validation-provider',{attrs:{"name":"logoImg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"logoFileImg",attrs:{"disabled":!_vm.canEdit,"name":"logoFileImg","hidden":"","plain":""},model:{value:(_vm.selectedLogo),callback:function ($$v) {_vm.selectedLogo=$$v},expression:"selectedLogo"}}),(!_vm.selectedLogo || _vm.selectedLogo.length == 0)?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 512, height: 512 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/favIco'),"label-for":"favIco"}},[_c('div',{staticClass:"logoimgPreview",on:{"click":function($event){return _vm.$refs.favIcoFileImg.$el.click()}}},[_c('label',{attrs:{"for":"logoFileImg"}},[(_vm.favIco)?_c('img',{staticClass:"img",attrs:{"src":_vm.favIco}}):_c('div',{staticClass:"addMediaBlock"},[_c('img',{staticClass:"img",attrs:{"src":_vm.addImageIcon}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.settings/generalSettings/addFavIco")))])])])]),_c('validation-provider',{attrs:{"name":"logoImg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"favIcoFileImg",attrs:{"disabled":!_vm.canEdit,"hidden":"","plain":""},model:{value:(_vm.selectedFavIco),callback:function ($$v) {_vm.selectedFavIco=$$v},expression:"selectedFavIco"}}),(!_vm.selectedFavIco || _vm.selectedFavIco.length == 0)?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 512, height: 512 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"actions",attrs:{"cols":"6","sm":"12"}},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.busyButton,"rounded":"","opacity":"0.7","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"disabled":!_vm.canEdit,"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("g.settings/generalSettings/submitButton"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }