<template>
  <loading v-if="isLoading" />
  <div class="general-setting" v-else>
    <validation-observer ref="generalSettingform" class="w-100">
      <b-form>
        <b-row class="mx-1">
          <b-col md="12" class="mb-1">
            <h2>
              {{ $t("g.settings/generalSetting") }}
            </h2>
          </b-col>
          <!-- site name  -->
          <b-col cols="12" md="4" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/sitename')"
              label-for="name"
            >
              <validation-provider #default="{ errors }" name="Name">
                <b-form-input
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.site_name"
                  :placeholder="$t('settings/placeholder/enter_the_name_here')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- **site name  -->
          </b-col>

          <!-- VirtualCurrency   -->
          <b-col md="4" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/VirtualCurrency')"
              label-for="VirtualCurrency"
            >
              <validation-provider #default="{ errors }" name="VirtualCurrency">
                <b-form-select
                  v-model="form_data.currency"
                  :options="currencyData"
                  value-field="currency"
                  :disabled="!canEdit"
                  text-field="currency"
                  :state="errors.length > 0 ? false : null"
                >
                </b-form-select>
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **VirtualCurrency   -->
          <!-- language   -->
          <b-col md="4" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/language')"
              label-for="language"
            >
              <validation-provider #default="{ errors }" name="language">
                <b-form-select
                  v-model="form_data.locale"
                  :disabled="!canEdit"
                  :options="locale"
                ></b-form-select>

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **language   -->
          <!-- Shipping Cost  -->
          <b-col md="3" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/shippingCost')"
              label-for="shipping cost"
            >
              <validation-provider #default="{ errors }" name="shipping cost">
                <b-form-input
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.shipping_cost"
                  :disabled="!canEdit"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **Shipping Cost   -->

          <!-- free shipping order value   -->
          <b-col md="3" class="mb-1">
            <b-form-group
              :label="
                $t('g.settings/generalSettings/free_shipping_order_value')
              "
              label-for="Min stock"
            >
              <validation-provider #default="{ errors }" name="Min stock">
                <b-form-input
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.free_shipping_order_value"
                  :disabled="!canEdit"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **free shipping order value   -->

          <!-- VAT   -->
          <b-col md="3" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/VAT')"
              label-for="VAT"
            >
              <validation-provider #default="{ errors }" name="VAT">
                <b-form-input
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.vat_amount"
                  :disabled="!canEdit"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **VAT   -->

          <!-- min stock   -->
          <b-col md="3" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/min_stock')"
              label-for="Min stock"
            >
              <validation-provider #default="{ errors }" name="Min stock">
                <b-form-input
                  type="number"
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.min_stock"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **min stock   -->

          <!-- site_description   -->
          <b-col md="12" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/site_description')"
              label-for="site_description"
            >
              <validation-provider
                #default="{ errors }"
                name="meta_description"
              >
                <b-form-textarea v-model="form_data.site_description" />

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **site_description   --><!-- meta_description   -->
          <b-col md="12" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/meta_description')"
              label-for="free_shipping_order"
            >
              <validation-provider
                #default="{ errors }"
                name="meta_description"
              >
                <b-form-textarea
                  :disabled="!canEdit"
                  v-model="form_data.meta_description"
                />

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **meta_description   -->
          <!-- meta_description   -->
          <b-col md="12" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/meta_keyword')"
              label-for="meta_keyword"
            >
              <validation-provider #default="{ errors }" name="meta_keyword">
                <b-form-textarea
                  :disabled="!canEdit"
                  v-model="form_data.meta_keyword"
                />

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **meta_description   -->
          <!-- logo img -->
          <b-col md="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/logoImg')"
              label-for="logoImg"
            >
              <div
                class="logoimgPreview"
                @click="$refs.logoFileImg.$el.click()"
              >
                <label for="logoFileImg">
                  <img :src="logo" v-if="logo" class="img" />
                  <div class="addMediaBlock" v-else>
                    <img :src="addImageIcon" />
                    <span>
                      {{ $t("g.settings/generalSettings/addlogoImg") }}
                    </span>
                  </div>
                </label>
              </div>
              <validation-provider #default="{ errors }" name="logoImg">
                <b-form-file
                  :disabled="!canEdit"
                  name="logoFileImg"
                  ref="logoFileImg"
                  hidden
                  plain
                  v-model="selectedLogo"
                />
                <RecommendationUploadImage
                  v-if="!selectedLogo || selectedLogo.length == 0"
                  :dimensions="{ width: 512, height: 512 }"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **logo img -->
          <!-- facIco img -->
          <b-col md="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/favIco')"
              label-for="favIco"
            >
              <div
                class="logoimgPreview"
                @click="$refs.favIcoFileImg.$el.click()"
              >
                <label for="logoFileImg">
                  <img :src="favIco" v-if="favIco" class="img" />
                  <div class="addMediaBlock" v-else>
                    <img :src="addImageIcon" class="img" />
                    <span>{{
                      $t("g.settings/generalSettings/addFavIco")
                    }}</span>
                  </div>
                </label>
              </div>
              <validation-provider #default="{ errors }" name="logoImg">
                <b-form-file
                  :disabled="!canEdit"
                  ref="favIcoFileImg"
                  hidden
                  plain
                  v-model="selectedFavIco"
                />
                <RecommendationUploadImage
                  v-if="!selectedFavIco || selectedFavIco.length == 0"
                  :dimensions="{ width: 512, height: 512 }"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **facIco img -->
          <!-- whatsApp   -->
          <!-- <b-col md="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/whatAppPhonenumber')"
              label-for="whatAppPhonenumber"
            >
              <validation-provider
                #default="{ errors }"
                name="whatAppPhonenumber"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  v-model="form_data.site_phone"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- **whatsApp  -->
          <!-- loginBy  -->
          <!-- <b-col md="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/loginBy')"
              label-for="loginBy"
            >
              <validation-provider #default="{ errors }" name="loginBy">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="$store.state.userData.userData.email"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- **loginBy   -->

          <b-col class="actions" cols="6" sm="12">
            <b-overlay
              :show="busyButton"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                :disabled="!canEdit"
                variant="primary"
                class=""
                @click="submit"
              >
                {{ $t("g.settings/generalSettings/submitButton") }}
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormFile,
  BRow,
  BButton,
  BOverlay,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import loading from "@/components/shared/loading/loading.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  name: "Settings",
  components: {
    BCol,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BRow,
    BButton,
    loading,
    BOverlay,
    BFormSelect,
    BFormTextarea,
    RecommendationUploadImage,
  },
  data() {
    return {
      form_data: {
        site_name: null,
        site_logo: null,
        locale: null,
        currency: null,
        whatsapp: null,
        favicon: null,
        vat_amount: null,
        shipping_cost: null,
        meta_description: null,
        site_description: null,
        min_stock: null,
        free_shipping_order_value: null,
        meta_keyword: null,
      },
      locale: [
        { text: this.$t("g.settings/language/ar"), value: "ar" },
        { text: this.$t("g.settings/language/en"), value: "en" },
      ],
      currencyData: [],
      archive: {},
      addImageIcon: require("@/assets/images/icons/file-icons/importMediaIcon.svg"),
      isLoading: true,
      selectedLogo: null,
      selectedFavIco: null,
      busyButton: false,
    };
  },
  created() {
    this.getData();
    this.getCurrencyData();
  },
  computed: {
    logo() {
      if (this.selectedLogo) {
        return URL.createObjectURL(this.selectedLogo);
      } else if (this.form_data.site_logo) {
        return this.form_data.site_logo;
      } else {
        return false;
      }
    },
    favIco() {
      if (this.selectedFavIco) {
        return URL.createObjectURL(this.selectedFavIco);
      } else if (this.form_data.favicon) {
        return this.form_data.favicon;
      } else {
        return false;
      }
    },
    canEdit() {
      return (
        this.globalPermission.settings && this.globalPermission.settings.update
      );
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("admin/settings");
        if (response.status === 200) {
          const wantedKeys = Object.keys(this.form_data);
          response.data.data.map((ele) => {
            if (wantedKeys.includes(ele.key)) {
              if (ele.key === "site_logo" || ele.key === "favicon") {
                this.form_data[ele.key] = ele.value?.path;
                this.archive[ele.key] = ele.value?.path;
              } else {
                this.form_data[ele.key] = ele.value?.value;
                this.archive[ele.key] = ele.value?.value;
              }
            }
          });
        }
      } catch (err) {
        this.$helpers.handleError(err);
        this.isLoading = false;

        this.$helpars.handelError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async getCurrencyData() {
      try {
        this.isLoading = true;
        const response = await this.$http.get("admin/countries");
        this.currencyData = response.data.data;
      } catch (error) {
        this.$helpars.handelError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      try {
        this.busyButton = true;
        const validatetion = this.$refs.generalSettingform.validate();
        if (validatetion) {
          const arrToEdit = [];
          for (let item in this.form_data) {
            const mediaPart = ["site_logo", "favicon"];
            if (!mediaPart.includes(item)) {
              if (this.archive[item] != this.form_data[item]) {
                arrToEdit.push({ key: item, value: this.form_data[item] });
              }
            } else if (mediaPart.includes(item)) {
              if (item == "site_logo" && this.selectedLogo) {
                arrToEdit.push({ key: item, value: this.selectedLogo });
              } else if (item == "favicon" && this.selectedFavIco) {
                arrToEdit.push({ key: item, value: this.selectedFavIco });
              }
            }
          }
          arrToEdit.map(async (ele) => {
            const formData = new FormData();
            formData.append("value", ele.value);
            const request = await this.$http.post(
              `admin/settings/${ele.key}?_method=put`,
              formData
            );
            if (request.status === 200 || request.status === 201) {
              this.getData();
              this.getCurrencyData();
              this.$helpers.makeToast("success", request.data.message);
              this.busyButton = false;
            }
          });
        }
      } catch (errors) {
        setTimeout(() => {
          this.busyButton = false;
        }, 700);
        this.$helpers.handleError(errors);
      } finally {
        setTimeout(() => {
          this.busyButton = false;
        }, 700);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
